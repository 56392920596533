import React, { useState } from "react"
import { shuffle, attempt, isError } from "lodash"
import dJSON from "dirty-json"
import ClipboardButton from "react-clipboard.js"

const InputBlock = () => {
  const [value, setValue] = useState("")

  return (
    <>
      <div style={{ marginBottom: `1rem` }}>
        <label htmlFor="mainInput">
          Insert your JSON array and click <i>Shuffle</i>
        </label>
        <textarea
          id="mainInput"
          style={{
            width: `100%`,
            resize: `none`,
            padding: `0.5rem`,
            minHeight: `40vh`,
            marginTop: `0.45rem`,
          }}
          placeholder='["insert", "your", "json", "here"]'
          value={value}
          onChange={(event) => {
            setValue(event.target.value)
          }}
        />
      </div>

      <div>
        <button
          className="primaryButton"
          onClick={() => {
            console.log(value)
            const parsedResult = attempt(dJSON.parse.bind(null, value))
            if (isError(parsedResult)) {
              return alert("Can not parse JSON")
            }
            setValue(JSON.stringify(shuffle(parsedResult)))
          }}
        >
          Shuffle JSON
        </button>

        <ClipboardButton
          data-clipboard-text={value}
          style={{
            marginLeft: `5px`,
            cursor: `pointer`,
            borderRadius: `5px`,
            padding: `5px 10px`,
          }}
          className="secondaryButton"
        >
          Copy to clipboard
        </ClipboardButton>
      </div>
    </>
  )
}
export default InputBlock
