import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import InputBlock from "../components/input-block"

const IndexPage = () => (
  <Layout>
    <SEO title="Shuffle JSON" />
    <InputBlock />
    <h2
      style={{
        marginTop: `1.5rem`,
      }}
    >
      About JSON Shuffle
    </h2>
    <p>
      JSON Shuffle is a tool that will randomize the order of objects in a JSON
      array, shuffling it. Copy and paste, or directly type your JSON array into
      the editor above, and press "Shuffle JSON".{" "}
    </p>
  </Layout>
)

export default IndexPage
